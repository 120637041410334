import React, { useEffect, useLayoutEffect, useState } from 'react'
import './index.scss'
import Constants from '../../constants'
import StorageService from '../../services/StorageService'
import { Input } from 'components/input'
import InputV2 from 'components/InputV2'
import ButtonV2 from 'components/Buttonv2'
import { MdEdit } from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'
import { MdPersonAddAlt } from 'react-icons/md'
import { IoMdInformationCircle } from 'react-icons/io'
import InputSelect from 'components/InputSelect'
import { Loader } from 'components/loader'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import HttpClient from 'services/v3/http.client'
import httpClientV2 from 'services/v2/httpClientv2'
import { ImStarEmpty } from 'react-icons/im'
import ButtonV3 from 'components/Buttonv3'

//Um dia irei refatorar isto...pois esta vergonhoso!

export default function EditUserInfoForm({ data, isSolocitation }) {
    const MySwal = withReactContent(Swal)

    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState()
    const [login, setLogin] = useState('')
    const [phone, setPhone] = useState('')
    const [editPageVisible, setEditPageVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedUserData, setSelectedUserData] = useState<any>({})
    const [userToEdit, setUserToEdit] = useState<any>({})
    const [addOrEdit, setAddOrEdit] = useState('')
    const [cliID, setCliID] = useState('')
    const [vendedorId, setVendedorId] = useState('')
    const [solID, setSolID] = useState('')

    useLayoutEffect(() => {
        setIsLoading(true)
        loadInfo(data, isSolocitation)
    }, [])

    async function loadInfo(datas?, Solocitation?) {
        if (Solocitation && datas.cnpj && datas.cnpj != '') {
            await httpClientV2
                .get(`/external/get/lojas/cnpj?cnpj=${datas.cnpj}`)
                .then((response) => {
                    let lojas = {
                        lojas: response,
                    }

                    setSelectedUserData(lojas)
                    setCliID(lojas.lojas[0].cli_id)
                    handleAddUser(datas, response)
                })
            handleAddUser(datas, Solocitation)
        } else {
            //  await HttpClient.get(`/clients/direct/${data.id}/shopping`,)
            await HttpClient.get(
                `/clients/direct/${cliID ? cliID : data.loj_id}/shopping`
            ).then((response) => {
                setSelectedUserData(response)
            })
        }

        setEditPageVisible(false)
        setIsLoading(false)
    }

    async function handleAddUser(cli?, solicitation?) {
        if (cli && cli.cnpj != '') {
            await setAddOrEdit('add')
            setVendedorId(cli.vendId)
            await handleSetName(cli.name)
            await handleSetEmail(cli.email)
            await handleSetCompany(cli.company)
            await handleSetPhone(cli.phone)
            await handleSetPassword(cli.password)
            setSolID(cli.solID)
            await setUserToEdit({})
        } else if (cli && solicitation) {
            let user
            setSolID(cli.solID)

            cli.rep.forEach((element) => {
                if (element.id == cli.vendId) {
                    user = element
                }
            })
            setVendedorId(user.id)
            await setAddOrEdit('add')
            await handleSetName(user.name)
            await handleSetEmail(user.email)
            await handleSetCompany('')
            await handleSetPhone(user.phone)
            await handleSetPassword(user.password)

            await setUserToEdit({})
        } else {
            await setAddOrEdit('add')
            await handleSetName('')
            await handleSetEmail('Login@email.com')
            await handleSetCompany('')
            await handleSetPhone('')
            await setUserToEdit({})
        }

        setEditPageVisible(true)
    }
    function handleSetName(value: any) {
        setName(value)
    }
    function handleSetCompany(value: any) {
        setCompany(value)
    }
    function handleSetEmail(value: any) {
        setEmail(value)
    }
    function handleSetPassword(value: any) {
        setPassword(value)
    }
    function handleSetLogin(value: any) {
        setLogin(value)
    }
    function handleSetPhone(value: any) {
        setPhone(value)
    }

    async function handleEdit(item) {
        await setAddOrEdit('edit')

        await handleSetName(item.usu_nome)
        await handleSetEmail(item.usu_email)
        await handleSetCompany(item.usu_idLoja)
        await handleSetPhone(item.usu_telefone)
        await setUserToEdit(item)

        setEditPageVisible(true)
    }
    async function handleRemove(item) {
        MySwal.fire({
            title: 'Você quer excluir este usuário?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                HttpClient.delete(`/clients/direct/${item.usu_id}/shopping`)
                    .then(() => loadInfo())
                    .catch((err) => alert('Erro ao excluir, Tente novamente!'))
            }
        })
    }

    function alert(text) {
        window.alert(text)
    }

    async function handleSaveUserChanges(
        password,
        name,
        company,
        email,
        phone
    ) {
        setIsLoading(true)

        let body = {
            nome: name,
            telefone: phone,
            email: email,
            login: email,
            senha: password === undefined ? userToEdit.usu_senha : password,
            id_loja: company ? company : '',
        }
        let bodyPost = {
            nome: name,
            telefone: phone,
            email: email,
            login: email,
            senha: password === '********' ? userToEdit.usu_senha : password,
            id_loja: company ? company : '',
            //cli_id: data.id
            cli_id: selectedUserData.lojas[0].cli_id
                ? selectedUserData.lojas[0].cli_id
                : data.id,
            vendedor_id: vendedorId ? vendedorId : null,
            sol_id: solID ? solID : null,
        }

        if (body.email != 'Login@email.com') {
            addOrEdit == 'edit' ? editUser(body) : addUser(bodyPost)
        } else {
            window.alert('Preencha o campo Email ')
            setIsLoading(false)
        }
    }

    async function editUser(body) {
        await HttpClient.put(
            `/clients/direct/${userToEdit.usu_id}/shopping`,
            body
        )
            .then(() => loadInfo())
            .then(() => setPassword(undefined))

            .catch((err) => alert('Erro ao salvar, Tente novamente!'))
        setIsLoading(false)
    }
    async function addUser(body) {
        if (body.senha == undefined || body.senha == '') {
            alert('Defina uma senha.')
        } else {
            await HttpClient.post(`/clients/direct/shopping`, body)
                .then(() => loadInfo({ cnpj: undefined }, false))
                .catch((err) => console.log(err))
            setIsLoading(false)
        }
    }

    return (
        <div className="mainDiv">
            {isLoading ? (
                <Loader type="inline" />
            ) : (
                <>
                    {!editPageVisible && (
                        <>
                            <div className="page-table-content-overflow">
                                <div className="addButtonContainer">
                                    <div
                                        onClick={() => handleAddUser()}
                                        className="addUserButton"
                                    >
                                        <MdPersonAddAlt className="addUserIcon" />
                                        <p className="addUserText">
                                            Novo Usuário
                                        </p>
                                    </div>
                                </div>
                                {true && (
                                    <div className="NewListAddUserVendaDireta">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Usuário</th>
                                                    <th>Login/Email</th>
                                                    <th>Empresa</th>
                                                    <th>Telefone</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {selectedUserData &&
                                                    selectedUserData.directShopping?.map(
                                                        (item, index) => {
                                                            return (
                                                                <>
                                                                    <tr
                                                                        key={
                                                                            item.usu_nome
                                                                        }
                                                                    >
                                                                        <td data-label="Usuário">
                                                                            {
                                                                                item.usu_nome
                                                                            }
                                                                        </td>

                                                                        <td data-label="Login/Email">
                                                                            {
                                                                                item.usu_login
                                                                            }
                                                                        </td>

                                                                        <td data-label="Empresa">
                                                                            {selectedUserData.lojas.map(
                                                                                (
                                                                                    element
                                                                                ) => {
                                                                                    if (
                                                                                        item.usu_idLoja ==
                                                                                        element.loj_id
                                                                                    ) {
                                                                                        return element.loj_descricao
                                                                                    }
                                                                                }
                                                                            )}
                                                                            -
                                                                        </td>

                                                                        <td data-label="Telefone">
                                                                            {
                                                                                item.usu_telefone
                                                                            }
                                                                        </td>

                                                                        <td data-label="Ações">
                                                                            <MdEdit
                                                                                title="Editar"
                                                                                className="buttonTable"
                                                                                onClick={() =>
                                                                                    handleEdit(
                                                                                        item
                                                                                    )
                                                                                }
                                                                            />
                                                                            <IoMdTrash
                                                                                title="Excluir"
                                                                                className="buttonTable"
                                                                                onClick={() =>
                                                                                    handleRemove(
                                                                                        item
                                                                                    )
                                                                                }
                                                                            />
                                                                            {
                                                                                //  <MdNoteAdd title="Adicionar" className="buttonTable" onClick={() => handleRemove()} />
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </>
                        // <>

                        //     <div className="page-table-content-overflow">
                        //         <div className="addButtonContainer">
                        //             <div onClick={() => handleAddUser()} className="addUserButton">
                        //                 <MdPersonAddAlt className="addUserIcon" />
                        //                 <p className="addUserText">Novo Usuário</p>
                        //             </div>
                        //         </div>
                        //         {true && (

                        //             <table className="tableDiv">
                        //                 <thead className="tableHeader" >
                        //                     <tr className="headerSpacer"></tr>
                        //                     <tr className="headerTableTr">
                        //                         <th className="headerTableTh" scope="col">Usuário</th>
                        //                         <th className="headerTableTh" scope="col">Login/Email</th>
                        //                         <th className="headerTableTh" scope="col">Empresa</th>
                        //                         <th className="headerTableTh" scope="col">Telefone</th>
                        //                         <th className="headerTableTh" scope="col">Ações</th>
                        //                     </tr>
                        //                     <tr className="headerSpacer"></tr>
                        //                 </thead>
                        //                 <tr className="spacer"></tr>
                        //                 <tbody className="tableBody">
                        //                     {selectedUserData && selectedUserData.directShopping?.map((item, index) => {
                        //                         return (
                        //                             <>
                        //                                 <tr className="spacer"></tr>
                        //                                 <tr className='bodyTableTr trGray '>
                        //                                     <td className="bodyTableTd" data-label="name">{item.usu_nome}</td>

                        //                                     <td className="bodyTableTd" data-label="Login">{item.usu_login}</td>

                        //                                     <td className="bodyTableTd" data-label="company">{
                        //                                         selectedUserData.lojas.map(element => {
                        //                                             if (item.usu_idLoja == element.loj_id) {
                        //                                                 return element.loj_descricao
                        //                                             }
                        //                                         })

                        //                                     }</td>

                        //                                     <td className="bodyTableTd" data-label="phone">{item.usu_telefone}</td>

                        //                                     <td className="bodyTableTd" data-label="actions">
                        //                                         <MdEdit title="Editar" className="buttonTable" onClick={() => handleEdit(item)} />
                        //                                         <IoMdTrash title="Excluir" className="buttonTable" onClick={() => handleRemove(item)} />
                        //                                         {
                        //                                             //  <MdNoteAdd title="Adicionar" className="buttonTable" onClick={() => handleRemove()} />
                        //                                         }

                        //                                     </td>
                        //                                 </tr>

                        //                                 <tr className="spacer"></tr>
                        //                             </>
                        //                         )
                        //                     })
                        //                     }
                        //                 </tbody>
                        //             </table>
                        //         )}
                        //     </div>
                        // </>
                    )}
                    {editPageVisible && (
                        <div className="firstContainer">
                            <div
                                style={{ marginBottom: 30 }}
                                className="titleContainer"
                            >
                                <p className="titleText">
                                    {addOrEdit == 'edit'
                                        ? 'Editar Usuário'
                                        : 'Cadastro de usuário'}
                                </p>
                            </div>
                            <form
                                id="form1"
                                onSubmit={(e) => {
                                    e.preventDefault(),
                                        handleSaveUserChanges(
                                            password,
                                            name,
                                            company,
                                            email,
                                            phone
                                        )
                                }}
                            >
                                <div className="container">
                                    {
                                        //selectedCustomer != 1 ?
                                        <>
                                            <InputV2
                                                infoIconNull={false}
                                                mask={undefined}
                                                icon={<MdEdit />}
                                                id={'name'}
                                                label={'Nome'}
                                                value={name}
                                                type={'text'}
                                                placeholder={'Ex: João Silva'}
                                                onChange={(value) =>
                                                    handleSetName(
                                                        value.target.value
                                                    )
                                                }
                                            />
                                            <div className="containerSelect">
                                                <div
                                                    className={'labelContainer'}
                                                >
                                                    <label
                                                        id={'123'}
                                                        className="label"
                                                    >
                                                        Empresa{' '}
                                                    </label>
                                                    <IoMdInformationCircle className="icon" />
                                                </div>
                                                <div className="selectContainer">
                                                    <select
                                                        name="select"
                                                        className="inputSelect"
                                                        onChange={(e) =>
                                                            setCompany(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option value={''}>
                                                            selecione uma
                                                            empresaa
                                                        </option>

                                                        {selectedUserData &&
                                                            selectedUserData.lojas.map(
                                                                (item) => {
                                                                    return (
                                                                        <option
                                                                            selected={
                                                                                company ==
                                                                                item.loj_id
                                                                            }
                                                                            value={
                                                                                item.loj_id
                                                                            }
                                                                        >
                                                                            {
                                                                                item.loj_descricao
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            )}
                                                    </select>
                                                </div>
                                            </div>

                                            {
                                                // <div className="containerSelect">
                                                //      <label id={'123'} className="label" >Empresa </label>
                                                //      <div className="selectContainer">
                                                //          <select
                                                //              name="select"
                                                //              className="inputSelect"
                                                //              onChange={(e) => setCompany(e.target.value)}
                                                //          >
                                                //              <option value={1} >selecione uma empresa</option>
                                                //              {selectedUserData && selectedUserData.lojas.map(item => {
                                                //                  return (
                                                //                      <option selected={company == item.loj_id} value={item.loj_id}>{item.loj_descricao}</option>
                                                //                  )
                                                //              })}
                                                //          </select>
                                                //      </div>
                                                //  </div>
                                            }

                                            {
                                                //   <InputV2
                                                //       icon={<MdEdit />}
                                                //       id={'company'}
                                                //       label={'Empresa'}
                                                //       value={company}
                                                //       type={'text'}
                                                //       placeholder={'testeeee'}
                                                //       onChange={(value) => handleSetCompany(value.target.value)}
                                                //   />
                                            }
                                            <InputV2
                                                infoIconNull={false}
                                                mask={undefined}
                                                icon={<MdEdit />}
                                                id={'email'}
                                                label={'Login/Email'}
                                                value={email}
                                                type={'text'}
                                                placeholder={
                                                    'exemplo@email.com'
                                                }
                                                onChange={(value) =>
                                                    handleSetEmail(
                                                        value.target.value
                                                    )
                                                }
                                            />
                                            <InputV2
                                                infoIconNull={false}
                                                icon={<MdEdit />}
                                                id={'phone'}
                                                label={'Telefone'}
                                                value={phone}
                                                type={'text'}
                                                placeholder={'Ex: 81 999999999'}
                                                mask="99-999999999"
                                                onChange={(value) =>
                                                    handleSetPhone(
                                                        value.target.value
                                                    )
                                                }
                                            />
                                            <InputV2
                                                infoIconNull={false}
                                                mask={undefined}
                                                icon={<MdEdit />}
                                                id={'password'}
                                                label={'Senha'}
                                                value={undefined}
                                                type={'password'}
                                                placeholder={'********'}
                                                onChange={(value) =>
                                                    handleSetPassword(
                                                        value.target.value
                                                    )
                                                }
                                            />
                                            {
                                                //    <InputV2
                                                //        icon={<MdEdit />}
                                                //        id={'login'}
                                                //        label={'Login'}
                                                //        value={login}
                                                //        type={'text'}
                                                //        placeholder={'testeeee'}
                                                //        onChange={(value) => handleSetLogin(value.target.value)}
                                                //
                                                //    />
                                            }
                                        </>
                                    }
                                    <div></div>
                                </div>
                            </form>
                            <div className="footerContainer">
                                <div></div>

                                <div className={'buttonsContainer'}>
                                    <ButtonV2
                                        variant="cancel"
                                        //onClick={onClose}
                                        onClick={() => {
                                            setEditPageVisible(false)
                                        }}
                                        title={'Cancelar'}
                                    />
                                    <ButtonV2
                                        variant="save"
                                        onClick={() => {}}
                                        type={'submit'}
                                        title={'Salvar'}
                                        form={'form1'}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
