import { BaseModal } from 'components/BaseModal'
import { Button } from 'components/button'
import EditUserInfoForm from 'components/EditUserInfoForm'
import { FeedbackError } from 'components/feedback/error'
import { Loader } from 'components/loader'
import { PageHeader } from 'components/page-header'
import { ProjectContainer } from 'components/project-container'
import { UserContext } from 'contexts/user'
import { mask_cnpj } from 'helpers/mask.helper'
import { Customer } from 'interfaces/customer'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaUserTie } from 'react-icons/fa'
import {
    MdOutlineAssignmentLate,
    MdOutlineBlock,
    MdOutlineBadge,
} from 'react-icons/md'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import customers, { load_stores, load_clients_condition } from 'store/customer'
import { PaginationCustom } from '../../components/pagination-custom'
import Constants from '../../constants'
import { useNavigate } from 'react-router-dom'
import { BlacklistedModal } from './components/blacklisted-modal'
import { ClientCompaniesFilterComponent } from './components/filters'
import { MoreInformationModal } from './components/more-information-modal'
import { RegisterRequestsModal } from './components/register-requests-modal'
import { RepresentativesModal } from './components/representatives-modal'
import { WeekdayModal } from './components/weekday-modal'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import './index.scss'

import { BsTelephoneForward } from 'react-icons/bs'

import { Link } from 'react-router-dom'
import { IoMdTrash } from 'react-icons/io'
import { IoIosAddCircleOutline } from 'react-icons/io'

import { MdBlock } from 'react-icons/md'

import { AiOutlineClear } from 'react-icons/ai'
import { BsSearch, BsFillPersonPlusFill, BsCalendarDay } from 'react-icons/bs'
import NotificationBadge from 'react-notification-badge'
import ButtonV3 from 'components/Buttonv3'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { RoadMap } from 'components/roadmap'
import { CompanyDetailsModal } from '../buying-companies/components/companyDetailsModal'
import { PhoneWithWhats } from 'components/phoneWithWhats'
import { boxShadow } from 'html2canvas/dist/types/css/property-descriptors/box-shadow'
import { TbUsersPlus, TbUsersGroup } from 'react-icons/tb'
import ClientService from 'services/v3/clients.service'
import {
    DataClients,
    Store,
    ClientsCondition,
} from 'interfaces/clientsCondition'
import { PaginationCustomV3 } from 'components/pagination-customerV3'

const BuyingCompanies = ({ clients, load_clients, states }: any) => {
    const initialQueryParams = {
        page: 1,
        customerId: '',
        statuses: [Constants.STATUS_ACTIVE],
        state: '',
        cnpj: '',
        name: '',
        vendedor: '',
        ramo: '',
    }

    const navigate = useNavigate()

    const location = useLocation()
    const { hash } = useLocation()
    const [show_blacklist_modal, open_blacklisted] = useState<boolean>(false)
    const [showFilters, setShowFilters] = useState<boolean>(true)
    const [queryParams, setQueryParams] = useState(initialQueryParams)
    const [weekdayModal, setWeekdayModal] = useState<boolean>()
    const [registerAmount, setRegisterAmount] = useState<number>(0)
    const [registerModal, setRegisterModal] = useState<boolean>(hash === '#rrm')
    const [loading, setLoading] = useState<boolean>(true)

    const [modalData, setModalData] = useState<any>()
    const [modalVisible, setModalVisible] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [isSolicitation, setModalIsSolicitation] = useState(Boolean)
    const [companyDataModalVisible, setCompanyDataModalVisible] =
        useState(false)
    const [showClearButton, setShowClearButton] = useState(false)
    const { user, can } = useContext(UserContext)
    const [clientStatus, setClientStatus] = useState(
        can('DIRECT_ORDER_ONLY', 'CLIENT_COMPANY') ? 'direct' : 'cot'
    )
    const iconRef = useRef<any>(null)

    const getQueryParam = (param) => {
        const searchParams = new URLSearchParams(location.search)
        return searchParams.get(param)
    }

    const [clientsData, setClientsData] = useState<DataClients[]>([])
    const [clientsCondition, setClientsCondition] = useState<ClientsCondition>()
    const [company, setCompany] = useState<DataClients | null>(null)
    const [store, setStore] = useState<Store | null>(null)
    const [refresPage, setRefreshPage] = useState(0)

    const cnpj = getQueryParam('cnpj')

    useEffect(() => {
        if (cnpj && can('READ', 'DIRECT_PURCHASE')) {
            setQueryParams({ ...queryParams, name: cnpj })
        }
    }, [cnpj])

    useEffect(() => {
        if (clientsCondition?.last_page == 1) {
            if (clientsCondition.data[0]?.store) {
                if (cnpj) {
                    autoOpenEditParams(clientsCondition.data[0]?.store)
                }
            }
        }
    }, [clientsCondition])

    function autoOpenEditParams(data) {
        setTimeout(() => {
            if (iconRef.current) {
                iconRef.current.click()
            }
        }, 500)
    }

    useEffect(() => {
        setLoading(true)
        async function loadClientsCondition() {
            if (user) {
                try {
                    const response = await ClientService.load_clients_condition(
                        queryParams
                    )
                    setClientsData(response.data)
                    setClientsCondition(response)
                    setLoading(false)
                } catch (error) {
                    console.error('Erro ao carregar clientes:', error)
                } finally {
                    setLoading(false)
                }
            }
        }
        loadClientsCondition()
    }, [queryParams, user, refresPage])

    const updatePage = () => {
        setRefreshPage((prev) => prev + 1) // Altera `refreshPage` para recarregar o `useEffect` com os clientes
        // setLoading(true)
        // load_clients().then(() => setLoading(false))
    }

    // ANTIGO ENDPOINT CLIENTS
    // useEffect(() => {
    //     setLoading(true)
    //     if (user) {
    //         load_clients({
    //             ...queryParams,
    //             seller_id: can('READ_ALL', 'CLIENT_COMPANY')
    //                 ? queryParams.vendedor
    //                 : user.id,
    //         }).then((res) => {
    //             setLoading(false)
    //         })
    //     }
    // }, [queryParams, user])

    const toogleFilters = () => setShowFilters(!showFilters)

    const getStatusClass = (client: any): string => {
        if (client.is_active) {
            return 'active'
        } else {
            return 'inactive'
        }
    }

    const status_name = (client: any): string => {
        if (client.is_active) {
            return 'Ativo'
        } else {
            return 'Inativo'
        }
    }

    const closeModal = (store: any) => {
        setRegisterModal(false)
    }

    const handleNewParamsSearch = (store: any) => {
        setQueryParams({ ...queryParams, name: store.sol_cnpj })

        const searchParams = new URLSearchParams(location.search)
        searchParams.set('cnpj', store.sol_cnpj)

        setTimeout(() => {
            navigate(
                {
                    pathname: location.pathname,
                    search: searchParams.toString(),
                },
                { replace: true }
            )
        }, 800)
    }

    const statusClass = (store: any): string => {
        if (store.conditions && store.conditions.length > 0) {
            return 'active'
        }

        return ''
    }

    const closeModalInformation = () => {
        const searchParams = new URLSearchParams(location.search)
        let hascnpj = searchParams.get('cnpj')
        if (hascnpj) {
            setShowClearButton(true)
        }
        searchParams.delete('cnpj')

        navigate(
            {
                pathname: location.pathname,
                search: searchParams.toString(),
            },
            { replace: true }
        )

        setCompany(null)
        updatePage()

        // if (user) {
        //     setLoading(true)
        //     load_clients({
        //         ...queryParams,
        //         seller_id: can('READ_ALL', 'CLIENT_COMPANY')
        //             ? queryParams.vendedor
        //             : user.id,
        //     }).then(() => setLoading(false))
        // }
    }

    const status_name_condition = (store: any): string => {
        if (store.count_condition !== 0) {
            return 'Ativo'
        }
        if (store.count_condition === 0) {
            return ''
        }
        return ''
    }

    const handleOpenModal = (data, isSolicitation) => {
        setModalTitle('Usuário da venda direta')
        setModalData(data)
        setModalIsSolicitation(isSolicitation)
        setModalVisible(true)
    }

    function filter_status(value) {
        if (clientStatus == 'cot' && value.sellers.length > 0) return true
        if (
            clientStatus == 'direct' &&
            value.head_office &&
            value.head_office.conditions.length > 0
        )
            return true
        return false
    }

    const MySwal = withReactContent(Swal)

    function deleteStore(customer) {
        MySwal.fire({
            title: (
                <div>
                    <p>Você irá excluir está empresa. Deseja continuar?</p>
                    <hr />
                </div>
            ),
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: 'red',
        }).then((resp) => {
            if (resp.isConfirmed && customer.id) {
                ClientService.delete_store(customer.id)
                    .then(() => {
                        setClientsData((prevClients) => {
                            const updatedClients = prevClients.filter(
                                (item) => item.id !== customer.id
                            )
                            return updatedClients
                        })
                        // updatePage()
                    })
                    .catch((error) => {
                        console.error('Erro ao excluir cliente:', error)
                    })
            }
        })
    }

    function handleOpenModalCompanyData(data) {
        setModalData(data)
        setCompanyDataModalVisible(true)
    }

    return (
        <ProjectContainer>
            <RoadMap
                pageLinks={[
                    {
                        title: 'Empresas Compradoras',
                        onclick: () => {},
                    },
                ]}
            ></RoadMap>
            <div style={{}} className="buying-companies">
                <header>
                    <PageHeaderV2 title="Empresas Compradoras" />
                </header>

                <div>
                    <div className="containerActionsButtons">
                        {!can('DIRECT_ORDER_ONLY', 'CLIENT_COMPANY') && (
                            <div
                                onClick={() => setClientStatus('cot')}
                                className={
                                    clientStatus == 'cot'
                                        ? 'nav-item nav-link active tabButton'
                                        : 'nav-item nav-link'
                                }
                            >
                                <TbUsersGroup></TbUsersGroup>
                                <p>Clientes Cotação</p>
                            </div>
                        )}

                        <div
                            style={{ marginLeft: '1vw' }}
                            onClick={() => setClientStatus('direct')}
                            className={
                                clientStatus == 'direct'
                                    ? 'nav-item nav-link active tabButton'
                                    : 'nav-item nav-link'
                            }
                        >
                            <TbUsersPlus></TbUsersPlus>
                            <p>Clientes Pedido Direto</p>
                        </div>
                        <div className="actionsItemButton">
                            <ButtonV3
                                Icon={
                                    <MdBlock className="iconButtonV3"></MdBlock>
                                }
                                title="Clientes Bloqueados"
                                onClick={() => open_blacklisted(true)}
                            />
                        </div>

                        {
                            // registerAmount > 0 && (
                            //     <Button
                            //         type="button"
                            //         className="c-btn-outline"
                            //         onClick={() => setRegisterModal(true)}
                            //     >
                            //         <BsFillPersonPlusFill />
                            //         <span>{'Solicitações de Cadastro'}</span>
                            //         <NotificationBadge
                            //             count={registerAmount}
                            //             containerStyle={{ width: 'auto' }}
                            //             style={{ marginTop: -10, right: -20 }}
                            //         />
                            //     </Button>
                            // )
                            // registerAmount > 0 && (
                            <div className="actionsItemButton">
                                <ButtonV3
                                    title="Solicitações de Cadastro"
                                    onClick={() => setRegisterModal(true)}
                                    Icon={
                                        <IoIosAddCircleOutline className="iconButtonV3"></IoIosAddCircleOutline>
                                    }
                                >
                                    <NotificationBadge
                                        count={registerAmount}
                                        containerStyle={{ width: 'auto' }}
                                        style={{
                                            marginTop: -28,
                                            right: -15,
                                            backgroundColor: '#FF7110',
                                        }}
                                    />
                                </ButtonV3>
                            </div>
                            // )
                        }
                        {/* {
                            <div className="actionsItemButton">
                                <ButtonV3
                                    title="Limpar filtros"
                                    onClick={() =>
                                        setQueryParams(initialQueryParams)
                                    }
                                    Icon={
                                        <IoMdTrash className="iconButtonV3" />
                                    }
                                ></ButtonV3>
                            </div>
                        } */}
                        {/* <div className="actionsItemButton">
                            <ButtonV3
                                filled
                                title="Cadastrar"
                                onClick={() => {
                                    navigate(
                                        `${Constants.ROUTE_CLIENTS}/register`
                                    )
                                }}
                            ></ButtonV3>
                        </div> */}

                        {
                            //    <Button onClick={() => toogleFilters()}>
                            //        <span className="flex items-center justify-center">
                            //            <i className="material-icons">
                            //                {showFilters ? 'close' : 'filter_alt'}
                            //            </i>
                            //            Filtros
                            //        </span>
                            //    </Button>
                        }
                    </div>
                </div>

                {
                    //  showFilters && (
                }
                {true && (
                    <ClientCompaniesFilterComponent
                        states={states}
                        customers={clients}
                        registerAmount={registerAmount}
                        onOpenRegisterModal={() => setRegisterModal(true)}
                        //onOpenWeekdayModal={ () => setWeekdayModal(true) }
                        onChange={(params: any) => setQueryParams(params)}
                        updateCot={(params: any) => setClientStatus(params)}
                        clientStatus={clientStatus}
                    />
                )}

                {!loading ? (
                    clientsData &&
                    clientsCondition !== undefined &&
                    clientsCondition.total > 0 ? (
                        <div className="list">
                            <table className="newTable">
                                <thead>
                                    <tr>
                                        <th>Clientes</th>
                                        <th>Nome fantasia</th>
                                        <th>CNPJ</th>
                                        <th>UF/Cidade</th>

                                        <th>Segmento</th>
                                        <th>Contato</th>
                                        {
                                            //   <th>Telefone</th>
                                        }

                                        <th className="text-center">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientsData.map(
                                        (
                                            customer: DataClients,
                                            idx: number
                                        ) => {
                                            const stores: any = []
                                            stores.push(
                                                <React.Fragment
                                                    key={`${customer.id}-${idx}`}
                                                >
                                                    <tr className="tableSpacer"></tr>
                                                    <tr
                                                    // className='TesteBlue'
                                                    >
                                                        <td data-label="Cliente">
                                                            <div className="headOfficeNameContainer">
                                                                {/* <div
                                                                                style={{
                                                                                    cursor: 'default',
                                                                                }}
                                                                            >
                                                                                <MdOutlineBadge
                                                                                    style={{
                                                                                        cursor: 'default',
                                                                                    }}
                                                                                    color="transparent"
                                                                                    // onClick={() => handleOpenModalCompanyData(customer)}
                                                                                />
                                                                            </div> */}
                                                                <span>
                                                                    teste
                                                                </span>
                                                                {customer.razao_social
                                                                    ? customer.store &&
                                                                      customer
                                                                          .store
                                                                          .loj_razao
                                                                        ? customer
                                                                              .store
                                                                              .loj_razao
                                                                        : ''
                                                                    : customer.razao_social ||
                                                                      ''}
                                                            </div>
                                                        </td>
                                                        <td data-label="Nome fantasia">
                                                            {customer.store &&
                                                            customer.store
                                                                .loj_nomeFantasia
                                                                ? customer.store.loj_nomeFantasia.toUpperCase()
                                                                : customer.nome_fantasia
                                                                ? customer.nome_fantasia.toUpperCase()
                                                                : ''}
                                                        </td>
                                                        <td
                                                            data-label="CNPJ"
                                                            className="text-nowrap"
                                                        >
                                                            {customer.CNPJ
                                                                ? mask_cnpj(
                                                                      customer
                                                                          .store
                                                                          .loj_cnpj
                                                                  )
                                                                : ''}
                                                        </td>
                                                        <td data-label="UF/Cidade">
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                >
                                                                    {customer.state
                                                                        ? customer
                                                                              .state
                                                                              .est_sigla
                                                                        : ''}
                                                                </div>
                                                                <div>
                                                                    {customer.state
                                                                        ? customer
                                                                              .state
                                                                              .est_capital
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td data-label="Segmento">
                                                            {customer.sector &&
                                                            customer.sector
                                                                .ramo_descricao
                                                                ? customer
                                                                      .sector
                                                                      .ramo_descricao
                                                                : ''}
                                                        </td>
                                                        <td data-label="Contato">
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                >
                                                                    {customer.nome_contato
                                                                        ? customer.nome_contato
                                                                        : ''}
                                                                </div>
                                                                <div>
                                                                    {customer.numero ? (
                                                                        <>
                                                                            <BsTelephoneForward
                                                                                style={{
                                                                                    marginRight: 4,
                                                                                }}
                                                                            />
                                                                            {customer.numero
                                                                                ? customer.numero
                                                                                : customer
                                                                                      .store
                                                                                      .loj_telefone}
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {
                                                                // </td>
                                                                // {store.phone
                                                                //     ? store.phone
                                                                //     : ''}
                                                                // <td>
                                                            }
                                                        </td>
                                                        {/* <td data-label='Status'>
                                                                        {
                                                                            //   <div
                                                                            //      className={`status ${statusClass(
                                                                            //          store
                                                                            //      )}`}
                                                                            //   >

                                                                            //   </div>
                                                                        }
                                                                        {status_name_condition(store)}
                                                                    </td> */}
                                                        <td data-label="Ações">
                                                            <div className="actionsTd">
                                                                <div></div>
                                                                {can(
                                                                    'READ',
                                                                    'DIRECT_PURCHASE'
                                                                ) &&
                                                                    clientStatus ==
                                                                        'direct' && (
                                                                        <i
                                                                            className="material-symbols-outlined"
                                                                            title="Parâmetros de venda direta"
                                                                            data-toggle="modal"
                                                                            data-target="#company-info-modal"
                                                                            onClick={() =>
                                                                                setStore(
                                                                                    customer.store
                                                                                )
                                                                            }
                                                                        >
                                                                            info
                                                                        </i>
                                                                    )}
                                                                {clientStatus ==
                                                                    'direct' && (
                                                                    <span
                                                                        style={{
                                                                            color: 'red',
                                                                        }}
                                                                        className="material-symbols-outlined"
                                                                        onClick={() =>
                                                                            deleteStore(
                                                                                customer
                                                                            )
                                                                        }
                                                                    >
                                                                        delete
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                            return (
                                                <React.Fragment key={idx}>
                                                    <tr className="tableSpacer"></tr>
                                                    <tr
                                                    //className='TesteRed'
                                                    >
                                                        <td data-label="Cliente">
                                                            <div className="headOfficeNameContainer">
                                                                {/* <div>
                                                                    <MdOutlineBadge
                                                                        onClick={() =>
                                                                            handleOpenModalCompanyData(
                                                                                customer
                                                                            )
                                                                        }
                                                                    />
                                                                </div> */}

                                                                {customer.razao_social
                                                                    ? customer.store &&
                                                                      customer
                                                                          .store
                                                                          .loj_razao
                                                                        ? customer
                                                                              .store
                                                                              .loj_razao
                                                                        : ''
                                                                    : customer.razao_social ||
                                                                      ''}
                                                            </div>
                                                        </td>
                                                        <td data-label="Nome fantasia">
                                                            {customer.store &&
                                                            customer.store
                                                                .loj_nomeFantasia
                                                                ? customer.store.loj_nomeFantasia.toUpperCase()
                                                                : customer.nome_fantasia
                                                                ? customer.nome_fantasia.toUpperCase()
                                                                : ''}
                                                        </td>
                                                        <td
                                                            data-label="CNPJ"
                                                            className="text-nowrap"
                                                        >
                                                            {customer.CNPJ
                                                                ? mask_cnpj(
                                                                      customer.CNPJ
                                                                  )
                                                                : ''}
                                                        </td>
                                                        <td data-label="UF/Cidade">
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                >
                                                                    {customer.city &&
                                                                    customer
                                                                        .city
                                                                        .cid_nome &&
                                                                    customer.state &&
                                                                    customer
                                                                        .state
                                                                        .est_sigla
                                                                        ? customer
                                                                              .state
                                                                              .est_sigla
                                                                        : ''}
                                                                </div>
                                                                <div>
                                                                    {customer.city &&
                                                                    customer
                                                                        .city
                                                                        .cid_nome
                                                                        ? customer
                                                                              .city
                                                                              .cid_nome
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td data-label="Segmento">
                                                            {customer.sector &&
                                                            customer.sector
                                                                .ramo_descricao
                                                                ? customer
                                                                      .sector
                                                                      .ramo_descricao
                                                                : ''}
                                                        </td>
                                                        <td data-label="Contato">
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                >
                                                                    {customer.nome_contato
                                                                        ? customer.nome_contato
                                                                        : ''}
                                                                </div>
                                                                {
                                                                    <div>
                                                                        {customer.numero ? (
                                                                            <>
                                                                                <PhoneWithWhats
                                                                                    phone={
                                                                                        customer.numero
                                                                                    }
                                                                                />
                                                                                {/* <BsTelephoneForward style={{ 'marginRight': 4 }} /> {customer.phone} */}
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                        {
                                                            //   <td>
                                                            //
                                                            //       {customer.phone
                                                            //           ? customer.phone
                                                            //           : ''}
                                                            //   </td>
                                                        }
                                                        {/* <td data-label='Status'>
                                                            {
                                                                //  <div
                                                                //      className={`status ${getStatusClass(
                                                                //          customer
                                                                //      )}`}
                                                                //  >
                                                                //  </div>
                                                            }
                                                            {status_name(
                                                                customer
                                                            )}
                                                        </td> */}
                                                        <td data-label="Ações">
                                                            <div className="actionsTd">
                                                                {can(
                                                                    'READ',
                                                                    'DIRECT_PURCHASE'
                                                                ) &&
                                                                    clientStatus ==
                                                                        'direct' && (
                                                                        <i
                                                                            ref={
                                                                                iconRef
                                                                            }
                                                                            className="material-symbols-outlined"
                                                                            data-toggle="modal"
                                                                            title="Parâmetros de venda direta"
                                                                            data-target="#company-info-modal"
                                                                            onClick={() =>
                                                                                setStore(
                                                                                    customer.store
                                                                                )
                                                                            }
                                                                        >
                                                                            info
                                                                        </i>
                                                                    )}
                                                                {clientStatus ==
                                                                    'cot' && (
                                                                    <i
                                                                        className="material-symbols-outlined"
                                                                        title="Vendedores de cotação"
                                                                        data-toggle="modal"
                                                                        data-target="#representatives-modal"
                                                                        onClick={() =>
                                                                            setCompany(
                                                                                customer
                                                                            )
                                                                        }
                                                                    >
                                                                        group
                                                                    </i>
                                                                )}

                                                                {
                                                                    //    <i
                                                                    //        className="material-icons people"
                                                                    //        title="Vendedores de cotação"
                                                                    //        data-toggle="modal"
                                                                    //        data-target="#representatives-modal"
                                                                    //        onClick={() => setCompany(customer)}
                                                                    //    >people</i>
                                                                }
                                                                {can(
                                                                    'READ',
                                                                    'DIRECT_PURCHASE'
                                                                ) &&
                                                                    clientStatus ==
                                                                        'direct' && (
                                                                        <MdOutlineAssignmentLate
                                                                            title="Usuários de venda direta"
                                                                            className="editIcon"
                                                                            onClick={() =>
                                                                                handleOpenModal(
                                                                                    customer,
                                                                                    false
                                                                                )
                                                                            }
                                                                        ></MdOutlineAssignmentLate>
                                                                    )}
                                                                {
                                                                    //   <i
                                                                    //       onClick={() => handleOpenModal(customer)}
                                                                    //   >{':)'}</i>
                                                                }
                                                                {clientStatus ==
                                                                    'direct' && (
                                                                    <span
                                                                        style={{
                                                                            color: 'red',
                                                                        }}
                                                                        className="material-symbols-outlined"
                                                                        onClick={() =>
                                                                            deleteStore(
                                                                                customer
                                                                            )
                                                                        }
                                                                    >
                                                                        delete
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {/* {stores ? stores : ''} */}
                                                </React.Fragment>
                                            )
                                        }
                                    )}
                                </tbody>
                            </table>
                            {clientsCondition.last_page == 1 &&
                            showClearButton ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: 30,
                                        padding: 5,
                                        width: '100%',
                                    }}
                                >
                                    <ButtonV3
                                        title="Mostrar todos"
                                        style={{
                                            boxShadow:
                                                '1px 1px 2px 2px rgba(0,0,0,0.1)',
                                        }}
                                        onClick={() =>
                                            setQueryParams(initialQueryParams)
                                        }
                                    />
                                </div>
                            ) : (
                                // <PaginationCustom
                                //     total={clientsCondition.last_page}
                                //     itemsName={'clientes'}
                                //     ItemsPorPage={clientsCondition.per_page}
                                //     currentPage={clientsCondition.current_page}
                                //     onPageChange={(page: number) =>
                                //         setQueryParams({ ...queryParams, page })
                                //     }
                                //     totalItems={clientsCondition.total}
                                // />
                                <PaginationCustomV3
                                    totalItems={clientsCondition.total} // Total de itens, por exemplo, 2890
                                    currentPage={clientsCondition.current_page} // A página atual
                                    handlePageChange={(page: number) =>
                                        setQueryParams({
                                            ...queryParams,
                                            page,
                                        })
                                    }
                                    itemsPerPage={clientsCondition.per_page} // Número de itens por página
                                    itemsName="clientes"
                                />
                            )}
                        </div>
                    ) : (
                        <FeedbackError message={Constants.MSG_DATA_NOT_FOUND} />
                    )
                ) : (
                    <Loader type="inline" />
                )}
            </div>

            <BlacklistedModal
                company={store}
                isVisible={show_blacklist_modal}
                onCloseCallback={() => open_blacklisted(false)}
            />

            <MoreInformationModal
                states={states}
                company={store}
                onCloseCallback={() => closeModalInformation()}
            />

            <RepresentativesModal
                company={company}
                onCloseCallback={() => setCompany(null)}
            />

            <RegisterRequestsModal
                isVisible={registerModal}
                searchParams={handleNewParamsSearch}
                onCloseCallback={closeModal}
                onRegisterAmount={setRegisterAmount}
                openEditUserInfoModal={handleOpenModal}
            />

            <WeekdayModal
                user={user}
                isVisible={weekdayModal}
                onCloseCallback={() => setWeekdayModal(false)}
            />
            <BaseModal
                data={modalData}
                title={modalTitle}
                isVisible={modalVisible}
                onCloseCallback={() => setModalVisible(false)}
                children={
                    <EditUserInfoForm
                        data={modalData}
                        isSolocitation={isSolicitation}
                    />
                }
            />
            <BaseModal
                data={null}
                title={`Cliente: ${modalData && modalData.nome_contato}`}
                isVisible={companyDataModalVisible}
                onCloseCallback={() => setCompanyDataModalVisible(false)}
                children={
                    // ----> MUDAR ATRIBUTOS PARA FUNCIONAR <----
                    <CompanyDetailsModal data={modalData}></CompanyDetailsModal>
                }
                modalBodyStyle={{ overflow: 'none' }}
            />
        </ProjectContainer>
    )
}

const mapStateToProps = (state: any) => {
    return {
        clients: state.customers.clients,
        states: state.requests.states,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    // load_clients: (params) => dispatch(load_stores(params)),
    load_clients: (params) => dispatch(load_clients_condition(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BuyingCompanies)
